import React from 'react';

const LoaderPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-[#0B1441] to-[#311782] text-white px-6">
      <div className="relative w-20 h-20 sm:w-24 sm:h-24 mb-8">
        <div className="absolute w-full h-full border-4 border-t-transparent border-purple-400 rounded-full animate-spin"></div>
      </div>

      <h1 className="text-2xl sm:text-3xl font-extrabold text-center leading-snug mb-4">
        Sit Back and Relax
      </h1>
      <p className="text-center text-lg sm:text-xl text-purple-300 leading-relaxed">
        We're preparing everything for your <span className="text-blue-300">amazing experience</span>. ✨
      </p>


      <div className="mt-10 flex space-x-2">
        <div className="w-3 h-3 bg-blue-400 rounded-full animate-bounce"></div>
        <div className="w-3 h-3 bg-purple-400 rounded-full animate-bounce delay-150"></div>
        <div className="w-3 h-3 bg-pink-400 rounded-full animate-bounce delay-300"></div>
      </div>
    </div>
  );
};

export default LoaderPage;
