import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import QRScannerDialog from "./QRScannerDialog";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const TicketVerification = ({ tickets, activity, eventID }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  // State variables
  const [currentRows, setCurrentRows] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [activityData, setActivityData] = useState({
    totalAttendees: activity.total_tickets ?? 0,
    presentAttendees: activity.checked_in ?? 0,
    checkedOutAttendees: activity.checked_out ?? 0,
    unverifiedAttendees: activity.unverified ?? 0,
  });
  const [ticketNumber, setTicketNumber] = useState("");
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState([]); // Changed to array
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const iconPath = process.env.PUBLIC_URL + "/icons/";

  // Load initial ticket data
  useEffect(() => {
    if (tickets && Array.isArray(tickets)) {
      setTicketData(tickets);
      // console.log("Tickets loaded:", tickets); // Debug log
    }
  }, [tickets]);

  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  // Handle scan button click
  const handleScanClick = () => {
    setIsQRScannerOpen(true);
  };

  // Enhanced filtered tickets with useMemo
  const filteredTickets = useMemo(() => {
    if (!Array.isArray(ticketData) || ticketData.length === 0) {
      return [];
    }

    let filtered = ticketData;

    // Apply search query filter
    if (searchQuery.trim()) {
      const lowercaseQuery = searchQuery.toLowerCase().trim();
      filtered = ticketData.filter((ticket) => {
        if (!ticket) return false;

        const searchableFields = [
          String(ticket.ticket_id || ""),
          String(ticket.payment_id || ""),
          String(ticket.ticket_type || ""),
          new Date(ticket.payment_date).toLocaleString(),
          ticket.check_in ? "checked in" : "not checked in",
          ticket.paid ? "validated" : "not validated",
        ];

        return searchableFields.some((field) =>
          field.toLowerCase().includes(lowercaseQuery)
        );
      });
    }

    // Apply status filter
    if (filterStatus.length > 0) {
      filtered = filtered.filter((ticket) => {
        let include = true;

        if (
          filterStatus.includes("checkedIn") &&
          ticket.check_in !== true
        ) {
          include = false;
        }

        if (
          filterStatus.includes("notCheckedIn") &&
          ticket.check_in !== false
        ) {
          include = false;
        }

        if (filterStatus.includes("validated") && ticket.paid !== true) {
          include = false;
        }

        if (filterStatus.includes("notValidated") && ticket.paid !== false) {
          include = false;
        }
        return include;
      });
    }

    return filtered;
  }, [ticketData, searchQuery, filterStatus]);

  // Handle Ticket Verification
  const handleVerifyTicket = async (ticket_id, event_id, index) => {
    // const updatedTickets = ticketData.map((ticket) => {
    //   if (ticket.transaction_number === ticketNumber) {
    //     setError("");
    //     return { ...ticket, status: "Paid", validated: true };
    //   }
    //   return ticket;
    // });

    // const ticketFound = ticketData.some(
    //   (t) => t.transaction_number === ticketNumber
    // );
    // if (!ticketFound) setError("Transaction ID not found. Try again!");
    // setTicketData(updatedTickets);
    // setTicketNumber("");
    try {
      const updatedRows = [...currentRows];
      let updatedTicketData = [];
      let paymentValidated = false;
      let ticketIndex = index;
      // find the index of the ticket in the data and check if payment has been validated
      if (index !== null) {
        paymentValidated = updatedRows[ticketIndex].paid;
      } else {
        updatedTicketData = [...ticketData];
        ticketIndex = updatedTicketData.findIndex(
          (item) => String(item.ticket_id) === String(ticket_id)
        );
        paymentValidated = updatedTicketData[ticketIndex].paid;
      }

      if (paymentValidated) {
        const response = await axios.post(
          `${apiUrl}/admin-event/verify-ticket`,
          {
            ticket_id,
            event_id,
          }
        );

        if (response.status === 200 || response.status === 201) {
          notify(response.data.message);
          if (index !== null) {
            updatedRows[index].check_in = true;
            setCurrentRows(updatedRows);
          } else {
            updatedTicketData[ticketIndex].check_in = true;
            setTicketData(updatedTicketData);
          }

          setActivityData((prevData) => ({
            ...prevData,
            presentAttendees: Math.max(0, prevData.presentAttendees + 1),
          }));
        }
      } else {
        notifyError(
          "The payment of the ticket you are trying to check-in hasn't been verified. Please verify the payment before proceeding to check in"
        );
      }
    } catch (e) {
      // Extract error message from axios error object
      const errorMessage =
        e.response?.data?.message ||
        e.message ||
        "An error occurred while verifying the ticket";
      setError(errorMessage);
    }
    setTicketNumber("");
  };

  // Handle Payment Validation
  const handleValidatePayment = async (payment_id, event_id, index) => {
    try {
      const response = await axios.post(
        `${apiUrl}/admin-event/validate-payment`,
        { payment_id, event_id }
      );

      if (response.status === 200 || response.status === 201) {
        // toast.success(response.data.message);
        notify(response.data.message);

        if (index !== null) {
          const updatedRows = [...currentRows];
          updatedRows[index].paid = true;
          setCurrentRows(updatedRows);
        }

        setActivityData((prevData) => ({
          ...prevData,
          unverifiedAttendees: Math.max(0, prevData.unverifiedAttendees - 1),
          checkedOutAttendees: Math.max(
            0,
            prevData.checkedOutAttendees + 1
          ),
        }));
      }
    } catch (e) {
      // Extract error message from axios error object
      const errorMessage =
        e.response?.data?.message ||
        e.message ||
        "An error occurred while validating the payment";
      setError(errorMessage);
    }
    setTicketNumber("");
  };

  // Calculate attendance percentage
  const calculatePercentage = () => {
    if (activityData.totalAttendees > 0) {
      return (
        (activityData.presentAttendees / activityData.totalAttendees) *
        100
      ).toFixed(2);
    }
    return "0.00";
  };

  // Update current rows based on pagination
  useEffect(() => {
    // console.log("Updating current rows. Filtered tickets:", filteredTickets); // Debug log
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const newRows = filteredTickets.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(newRows);
  }, [filteredTickets, currentPage, rowsPerPage]);

  // Pagination handlers
  const totalPages = Math.ceil(filteredTickets.length / rowsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Report Download
  const handleDownloadReport = () => {
    const csvData = [
      [
        "Ticket ID",
        "Ticket Status",
        "Ticket Type",
        "Purchase Time",
        "Payment ID",
        "Validation Status",
        "Contact Number"
      ],
      ...ticketData.map((ticket) => [
        ticket.ticket_id,
        ticket.check_in ? "Checked In" : "Unchecked",
        ticket.ticket_type,
        ticket.payment_date,
        ticket.payment_id,
        ticket.paid ? "Validated" : "Not Validated",
        ticket.contact
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");
    const link = document.createElement("a");
    link.href = encodeURI(csvContent);
    link.download = "ticket_report.csv";
    link.click();
  };

  // Enhanced Search Input Component
  const SearchInput = () => {
    const [localSearch, setLocalSearch] = useState("");
    const searchInputRef = useRef(null);

    const handleChange = (e) => {
      setLocalSearch(e.target.value);
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        searchInputRef.current?.focus();
        setSearchQuery(localSearch);
        setCurrentPage(1);
      }
    };

    const handleSearchClick = () => {
      setSearchQuery(localSearch);
      setCurrentPage(1);
    };

    const clearSearch = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLocalSearch("");
      setSearchQuery("");
      setCurrentPage(1);
      // Ensure focus returns to input after clearing
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 0);
    };

    return (
      <div className="relative mb-4" onClick={(e) => e.stopPropagation()}>
        <div className="relative">
          <div className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search..."
            value={localSearch}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            className="pl-10 border border-gray-300 px-3 py-2 text-left rounded-lg w-full max-w-sm focus:ring-2 focus:ring-blue-500 mt-2"
          />
          {localSearch && (
            <button
              type="button" // Prevent form submission
              onClick={clearSearch}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          )}
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            onClick={handleSearchClick}
            className="bg-blue-600 flex items-center justify-center gap-2 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition shadow mt-2"
          >
            Search
          </button>
        </div>

        {searchQuery && (
          <div className="mt-2 text-sm text-gray-500">
            Found {filteredTickets.length} matching tickets
            {searchQuery && ` for "${searchQuery}"`}
          </div>
        )}
      </div>

    );
  };

  const StatusFilter = () => {
    const handleCheckboxChange = (status) => {
      setFilterStatus((prev) =>
        prev.includes(status)
          ? prev.filter((item) => item !== status)
          : [...prev, status]
      );
    };
  
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
    };
    return (
      <div className="mb-4 relative">
      <label className="block text-gray-700 font-medium mb-2">
        Filter by Status:
        <button
          type="button"
          onClick={toggleDropdown}
          className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          {isDropdownOpen ? "▲" : "▼"} {/* Dropdown arrow */}
        </button>
      </label>
      <div
        className={`absolute left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md z-10 ${
          isDropdownOpen ? "" : "hidden"
        }`}
      >
        <label className="block px-4 py-2 hover:bg-gray-100">
          <input
            type="checkbox"
            className="mr-2 leading-tight"
            value="checkedIn"
            checked={filterStatus.includes("checkedIn")}
            onChange={() => handleCheckboxChange("checkedIn")}
          />
          <span className="text-sm">Checked In</span>
        </label>
        <label className="block px-4 py-2 hover:bg-gray-100">
          <input
            type="checkbox"
            className="mr-2 leading-tight"
            value="notCheckedIn"
            checked={filterStatus.includes("notCheckedIn")}
            onChange={() => handleCheckboxChange("notCheckedIn")}
          />
          <span className="text-sm">Not Checked In</span>
        </label>
        <label className="block px-4 py-2 hover:bg-gray-100">
          <input
            type="checkbox"
            className="mr-2 leading-tight"
            value="validated"
            checked={filterStatus.includes("validated")}
            onChange={() => handleCheckboxChange("validated")}
          />
          <span className="text-sm">Validated</span>
        </label>
        <label className="block px-4 py-2 hover:bg-gray-100">
          <input
            type="checkbox"
            className="mr-2 leading-tight"
            value="notValidated"
            checked={filterStatus.includes("notValidated")}
            onChange={() => handleCheckboxChange("notValidated")}
          />
          <span className="text-sm">Not Validated</span>
        </label>
      </div>
    </div>
    );
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen flex flex-col gap-6 ticket-verification-main-container">
      {/* Activity Tracking Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="font-bold text-gray-800 mb-4">Activity Tracking</h3>
        <div className="relative w-full bg-gray-200 h-4 rounded-full">
          <div
            className="absolute top-0 left-0 h-4 bg-green-500 rounded-full"
            style={{ width: `${calculatePercentage()}%` }}
          ></div>
        </div>
        <p className="text-green-700 font-bold mt-2">
          {calculatePercentage()}%
        </p>
        <p className="text-lg font-bold mt-4">
          Total Attendees: {activityData.totalAttendees}
        </p>
        <div className="flex flex-wrap gap-4 mt-4">
          <div className="bg-green-100 p-2 rounded text-green-600">
            Present attendees: {activityData.presentAttendees}
          </div>
          <div className="bg-yellow-100 p-2 rounded text-yellow-600">
            Checked out attendees: {activityData.checkedOutAttendees}
          </div>
          <div className="bg-red-100 p-2 rounded text-red-600">
            Unverified attendees: {activityData.unverifiedAttendees}
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="flex flex-wrap gap-4 justify-center">
        <button
          onClick={handleDownloadReport}
          className="bg-gray-200 flex items-center justify-center gap-2 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
        >
          <img
            src={`${iconPath}report-icon.svg`}
            alt="Report Icon"
            className="w-5 h-5"
          />
          Report
        </button>
        <button
          className="bg-blue-600 flex items-center justify-center gap-2 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition shadow"
          onClick={handleScanClick}
        >
          <img
            src={`${iconPath}scan-icon.svg`}
            alt="Scan Icon"
            className="w-5 h-5"
          />
          Scan Now
        </button>
      </div>

      <QRScannerDialog
        isOpen={isQRScannerOpen}
        onClose={() => setIsQRScannerOpen(false)}
      />

      {/* Verify Ticket Section */}
      <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
        <label className="block text-gray-700 font-medium mb-2">
          Enter Ticket ID to verify
        </label>
        <input
          type="text"
          placeholder="123234546897"
          value={ticketNumber}
          onChange={(e) => setTicketNumber(e.target.value)}
          className="border border-gray-300 px-3 py-2 rounded-lg w-full max-w-md focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => handleVerifyTicket(ticketNumber, eventID, null)}
          className="bg-gray-200 text-gray-700 px-4 py-2 mt-2 rounded-lg hover:bg-gray-300 transition w-full max-w-md"
        >
          Verify
        </button>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>

      {/* Search and Filter Section */}
      <div className="flex flex-col md:flex-row gap-4 items-center md:items-start w-full md:justify-between">
        <div className="w-full md:max-w-sm">
          <SearchInput />
        </div>
        <div className="w-full md:w-auto relative md:ml-auto md:mt-3">
          <StatusFilter />
        </div>
      </div>

      {/* Ticket Table Section */}
      <div className="overflow-x-auto">
        <table className="min-w-full w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Ticket ID
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Payment ID
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Ticket Type
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Purchase Time
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Check-in Status
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Payment Validation
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-300 bg-gray-200 text-center text-xs font-bold text-black-600 uppercase tracking-wider">
                Contact Number
              </th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((ticket, index) => (
              <tr key={ticket.ticket_id}>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {ticket.ticket_id}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                    {ticket.payment_id}
                </td>
                <td className="px-7 py-7  border-b border-gray-200 bg-grey-50 text-sm text-center w-full">
                    {ticket.ticket_type}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {new Date(ticket.payment_date).toLocaleString()}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                  {ticket.check_in ? (
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">Checked In</span>
                    </span>
                  ) : (
                    <button
                      onClick={() =>
                        handleVerifyTicket(ticket.ticket_id, eventID, index)
                      }
                      className="bg-yellow-100 hover:bg-yellow-200 text-yellow-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Check In
                    </button>
                  )}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                  {ticket.paid ? (
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">Validated</span>
                    </span>
                  ) : (
                    <button
                      onClick={() =>
                        handleValidatePayment(ticket.payment_id, eventID, index)
                      }
                      className="bg-red-100 hover:bg-red-200 text-red-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Validate
                    </button>
                  )}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-grey-50 text-sm text-center">
                    {ticket.contact}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      {/* Pagination Section */}
      <div className="mt-4 flex flex-col items-center justify-center gap-4">
          {/* Page Numbers */}
          <div className="flex justify-center items-center gap-2">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className={`px-3 py-1 rounded ${
                currentPage === 1
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              Previous
            </button>

            <div className="flex gap-1">
              {Array.from({ length: totalPages }, (_, index) => {
                const pageNumber = index + 1;
                const showPage =
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  Math.abs(pageNumber - currentPage) <= 2;

                if (!showPage) {
                  if (pageNumber === currentPage - 3 || pageNumber === currentPage + 3) {
                    return <span key={pageNumber} className="px-2">...</span>;
                  }
                  return null;
                }

                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 rounded ${
                      currentPage === pageNumber
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 hover:bg-gray-300"
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              })}
            </div>

            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === totalPages
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              Next
            </button>
          </div>

          {/* Rows per page selector */}
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              className="border rounded px-4 py-1 min-w-[80px] text-left appearance-none cursor-pointer"
              >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>
        <ToastContainer />
      </div>
  </div>
  );
};

export default TicketVerification;
