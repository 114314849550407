import React, { useState, useEffect, useRef, startTransition } from "react";
import { motion } from "framer-motion";
import SecondaryButton from "./SecondaryButton";
import SearchForm from "./SearchForm";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../logo.svg";
import axios from "axios"; // Import axios for making API calls
// import Tooltip from "./Tooltip";
// import PurpleButton from "./PurpleButton";

const Header = ({ searchBar }) => {
  const [userDetails, setUserDetails] = useState(null); // State to store user details
  const [isNavigating, setIsNavigating] = useState(false); // State for handling navigation
  const [menuVisible, setMenuVisible] = useState(false);
  const [profileMenuVisible, setProfileMenuVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const menuRef = useRef(null); // Ref for the menu container
  const hamburgerRef = useRef(null); // Ref for the hamburger button
  const profileMenuRef = useRef(null); // Ref for the profile menu container
  const profileMenuButtonRef = useRef(null);
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const handleLogoClick = (event) => {
      event.preventDefault();
      startTransition(() => {
        navigate("/home");
      });
    };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/auth/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails(response.data.userDetails);
            console.log(response.data.userDetails);
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMenuVisible(false);
        document.body.classList.remove('no-scroll');
      }
    };

    // Attach event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // To handle touch events

    // Clean up event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideProfile = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        !profileMenuButtonRef.current.contains(event.target)
      ) {
        setProfileMenuVisible(false);
        document.body.classList.remove('no-scroll');
      }
    };

    // Attach event listeners
    document.addEventListener("mousedown", handleClickOutsideProfile);
    document.addEventListener("touchstart", handleClickOutsideProfile); // To handle touch events

    // Clean up event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideProfile);
      document.removeEventListener("touchstart", handleClickOutsideProfile);
    };
  }, []);

  const handleMouseEnter = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleNavigation = (path) => {
    setIsNavigating(true);
    setTimeout(() => {
      navigate(path);
      setIsNavigating(false);
    }, 500); // 0.5-second delay for transition
  };

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    if (menuVisible) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
    setMenuVisible(!menuVisible);
  };

  const toggleProfileMenu = (event) => {
    event.stopPropagation();
    if (profileMenuVisible) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
    setProfileMenuVisible(!profileMenuVisible);
    
  };

  const fadeTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={fadeTransition}
    >
      <div
        className={`header-top-fixed one-page-nav top-0 left-0 right-0 py-4 z-50`}
      >
        <div className="flex justify-between items-center navbar">
          <div className="logo cursor-pointer" onClick={handleLogoClick}>
            <img src={Logo} alt="MOQAH.Pk" />
            <h6>MOQAH.PK</h6>
          </div>
          <div className="menu-container">
            <button
              ref={hamburgerRef}
              className="hamburger-menu"
              onClick={toggleMenu}
            >
              ☰
            </button>
            <ul
              ref={menuRef}
              className={`main-menu ${menuVisible ? "show" : ""}`}
            >
              <li className="mobile-auth-option">
                {userDetails === undefined ? (
                  <a onClick={handleLogin}>Login</a>
                ) : (
                  <a onClick={handleLogout}>Logout</a>
                )}
              </li>
              <li>
                <a
                  href="/admin?tab=dashboard"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/admin?tab=payment"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Ticket Verification
                </a>
              </li>
              <li>
                <a
                  href="/admin?tab=contact"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Support
                </a>
              </li>
              {/* <PurpleButton dest={"/add-event"} content={"Create your Event"} /> */}
            </ul>

            {userDetails === undefined ? (
              <div></div>
            ) : (
              <div className="vertical-line"></div>
            )}

            <button
            ref={profileMenuButtonRef}
              className="profile-button-wrapper"
              onClick={toggleProfileMenu}
            >
              <ul className="profile">
                <li>
                  {userDetails ? (
                    <div className="profile-details">
                      <img src={iconsPath + "placeholder.png"} alt="Profile" />
                      <div>
                        <h6 className="name">
                          {userDetails.first_name} {userDetails.last_name}
                        </h6>
                      </div>
                    </div>
                  ) : userDetails === undefined ? (
                    <div></div>
                  ) : (
                    <div className="profile-details">
                      <img src={iconsPath + "placeholder.png"} alt="Profile" />
                    </div>
                  )}
                </li>
                {userDetails === undefined ? (
                  <li>
                    <SecondaryButton content={"Login"} onClick={handleLogin} />
                  </li>
                ) : (
                  // <li>
                  //   <SecondaryButton content={"Logout"} onClick={handleLogout} />
                  // </li>
                  <></>
                )}
              </ul>
            </button>
            <ul
              ref={profileMenuRef}
              className={`profile-menu ${profileMenuVisible ? "show" : ""}`}
            >
              <li>
                <a href="/account">
                  <div className="profile-menu-row">
                    <div>
                      {userDetails ? (
                        <div className="profile-details">
                          <img
                            src={iconsPath + "placeholder.png"}
                            alt="Profile"
                          />
                          <div>
                            <h6 className="name">
                              {userDetails.first_name} {userDetails.last_name}
                            </h6>
                            <h6 className="username">
                                {userDetails.email}
                              </h6>
                          </div>
                        </div>
                      ) : userDetails === undefined ? (
                        <div></div>
                      ) : (
                        <div className="profile-details">
                          <img
                            src={iconsPath + "placeholder.png"}
                            alt="Profile"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              </li>
              <hr className="horizontal-line" />
              <li>
                <a
                  href="/account"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <div className="profile-menu-row">
                    <div>
                      <img src={iconsPath + "user.svg"} alt="my account" />
                      <h6>My Account</h6>
                    </div>
                    <img
                      src={iconsPath + "chevron_forward.svg"}
                      alt="forward"
                    />
                  </div>
                </a>
              </li>
              <li>
                <a href="/account?tab=payment">
                  <div className="profile-menu-row">
                    <div>
                      <img src={iconsPath + "purchases.svg"} alt="purchases" />
                      <h6>My Purchases</h6>
                    </div>
                    <img
                      src={iconsPath + "chevron_forward.svg"}
                      alt="forward"
                    />
                  </div>
                </a>
              </li>
              <hr className="horizontal-line" />
              <li>
                <a href="/account?tab=contact">
                  <div className="profile-menu-row">
                    <div>
                      <img src={iconsPath + "support.svg"} alt="support" />
                      <h6>Support/Contact Us</h6>
                    </div>
                    <img
                      src={iconsPath + "chevron_forward.svg"}
                      alt="forward"
                    />
                  </div>
                </a>
              </li>
              <li>
                <button onClick={handleLogout}>
                  <div className="profile-menu-row">
                    <div>
                      <img src={iconsPath + "logout.svg"} alt="logout" />
                      <h6>Logout</h6>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        {searchBar ? <SearchForm /> : null}
      </div>
    </motion.div>
  );
};

export default Header;
