import React, { useState } from 'react';
import PropTypes from 'prop-types';

const EventSelection = ({ events, onSelectEvent }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSelectEvent = (eventId) => {
    // Set the selected event
    setSelectedEvent(eventId);
    
    // Immediately apply the selection
    onSelectEvent(eventId);
  };

  // Handle empty events case
  if (!events || events.length === 0) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">No Events Available</h1>
          <p className="text-gray-600 mb-6">
            There are no events associated with your account. Please contact your administrator.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Refresh
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-6xl mx-auto">
        <h1 className="text-2xl font-bold text-center text-gray-800 mb-2">Select your event</h1>
        <p className="text-center text-gray-600 mb-8">
          Kindly select the relevant event, whose dashboard you are trying to view
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {events.map((event) => (
            <div
              key={event.eventID}
              className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={event.advBanner || `${process.env.PUBLIC_URL}/placeholder-event.jpg`}
                  alt={event.eventName}
                  className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.PUBLIC_URL}/placeholder-event.jpg`;
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                <h3 className="absolute bottom-3 left-3 text-white font-bold text-lg">
                  {event.eventName}
                </h3>
              </div>
              <div className="p-4">
                <button
                  onClick={() => handleSelectEvent(event.eventID)}
                  className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md transition-colors duration-300"
                >
                  View Dashboard
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

EventSelection.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      eventID: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      advBanner: PropTypes.string
    })
  ).isRequired,
  onSelectEvent: PropTypes.func.isRequired
};

export default EventSelection;