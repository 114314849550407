import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import TicketVerification from "../components/TicketVerification";
import ContactUs from "../components/ContactUs";
import NewLoader from "./NewLoader";
import EventSelection from "../components/EventSelection";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/HeaderAdmin";

const AdminPage = () => {
  const [eventID, setEventID] = useState(null);
  const [eventsList, setEventsList] = useState([]);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [adminDetails, setAdminDetails] = useState(null);
  const [dashboardData, setDashboardData] = useState({});
  const [activityData, setActivityData] = useState({});
  const [attendeeData, setAttendeeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showEventSelection, setShowEventSelection] = useState(false);
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const location = useLocation();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Handle tab from URL query parameter
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab) setActiveTab(tab);

    // Fetch Admin Details and Events
    const fetchAdminDetails = async () => {
      setLoading(true); // Set loading to true before fetching data
      const tokenAdmin = localStorage.getItem("tokenAdmin");
      if (tokenAdmin) {
        try {
          console.log("Verifying token");
          const response = await axios.post(`${apiUrl}/admin-event/verify-token`, {
            tokenAdmin,
          });

          if (response.status === 200) {
            const adminData = response.data.adminDetails;
            setAdminDetails(adminData);

            const orgId = adminData.orgId;
            if (orgId) {
              try {
                const eventsResponse = await axios.post(`${apiUrl}/admin-event/get-events`, { orgId });

                if (eventsResponse.status === 200) {
                  const events = eventsResponse.data.events;
                  setEventsList(events);

                  const storedEventID = localStorage.getItem("selectedEventId");
                  const isValidStoredEvent = storedEventID &&
                    events.some(event => event.eventID === storedEventID);

                  if (isValidStoredEvent) {
                    setEventID(storedEventID);
                  } else if (events.length === 1) {
                    setEventID(events[0].eventID);
                    localStorage.setItem("selectedEventId", events[0].eventID);
                  } else if (events.length > 1) {
                    if (storedEventID && !isValidStoredEvent) {
                      localStorage.removeItem("selectedEventId");
                    }
                    setShowEventSelection(true);
                  }
                } else {
                  // Check for 404 status if it's not 200
                  if (eventsResponse.status === 404) {
                    alert("No active events found for this organization");
                  } else {
                    // For any other status codes, log and alert
                    alert(`Unexpected error: ${eventsResponse.statusText}`);
                  }
                }
              } catch (error) {
                // Handle network or unexpected errors here
                if (error.response && error.response.status === 404) {
                  // Specific handling for 404 errors from the server
                  alert("No active events found for this organization");
                  navigate('/admin-access')

                } else {
                  // General error handling (for network errors, 500 errors, etc.)
                  console.error("Error fetching events:", error);
                }
              }
            }
          } else {
            setAdminDetails(undefined);
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          setAdminDetails(undefined);
        } finally {
          setLoading(false); // Set loading to false after all operations
        }
      } else {
        setAdminDetails(undefined);
        setLoading(false); // No token, so no need to show loader
      }
    };

    fetchAdminDetails();
  }, [location.search, apiUrl]);

  // Fetch event data when eventID changes
  useEffect(() => {
    const fetchEventData = async () => {
      if (!eventID) return;

      // Reset all data first to prevent data mixing between events
      setDashboardData({});
      setActivityData({});
      setAttendeeData({});
      setLoading(true);

      try {
        // Use a single request to fetch all data or implement proper data isolation
        const requests = [
          axios.post(`${apiUrl}/admin-event/dashboard`, { event_id: eventID }),
          axios.post(`${apiUrl}/admin-event/activity`, { event_id: eventID }),
          axios.post(`${apiUrl}/admin-event/attendees`, { event_id: eventID })
        ];

        const [dashboardResponse, activityResponse, attendeeResponse] = await Promise.all(requests);

        // Update state with the data we successfully retrieved
        setDashboardData(dashboardResponse.data || {});
        setActivityData(activityResponse.data || {});
        setAttendeeData(attendeeResponse.data || {});

        // Hide event selection once we have fetched data
        setShowEventSelection(false);
      } catch (error) {
        console.error(`Error fetching data for event ${eventID}:`, error);
        // Don't show event selection on error - just show whatever data we have
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventID, apiUrl]);

  const handleEventSelect = (selectedEventId) => {
    if (!selectedEventId) return;

    // Verify selected event exists in our list
    const eventExists = eventsList.some(event => event.eventID === selectedEventId);
    if (!eventExists) {
      console.error("Selected event not found in events list");
      return;
    }

    // Update state and localStorage
    localStorage.setItem("selectedEventId", selectedEventId);
    // Reset states to prevent data mixing
    setDashboardData({});
    setActivityData({});
    setAttendeeData({});
    // Set event ID last to trigger the useEffect
    setEventID(selectedEventId);
    setShowEventSelection(false);
  };

  const handleChangeEvent = () => {
    setShowEventSelection(true);
  };

  const getFormattedDate = () => {
    const today = new Date();
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "2-digit",
    };
    return today.toLocaleDateString("en-GB", options).replace(",", ",");
  };

  // Show event selection screen if required
  if (showEventSelection) {
    return (
      <>
        <Header searchBar={false} />
        <EventSelection
          events={eventsList}
          onSelectEvent={handleEventSelect}
        />
        <Footer />
      </>
    );
  }

  // Show loader while loading
  if (loading) {
    return <NewLoader />;
  }

  // Find current event name
  const currentEvent = eventsList.find(event => event.eventID === eventID);
  const eventName = currentEvent ? currentEvent.eventName : "Selected Event";

  // Render Content Based on Active Tab
  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <Dashboard data={dashboardData} />;
      case "payment":
        return <TicketVerification activity={activityData} tickets={attendeeData} eventID={eventID} />;
      case "contact":
        return (
          <div className="contact-container">
            <ContactUs />
          </div>
        );
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <>
      <Header searchBar={false} />
      <div className="main-body">
        <div className="account-page-container">
          {/* Sidebar */}
          <div className="vertical-sidebar">
            <button
              className={`icon-button ${activeTab === "dashboard" ? "active" : ""}`}
              onClick={() => setActiveTab("dashboard")}
            >
              <img
                className="navigation-icon"
                src={`${iconPath}${activeTab === "dashboard" ? "dashboard-active.svg" : "dashboard-inactive.svg"}`}
                alt="dashboard-icon"
              />
            </button>
            <button
              className={`icon-button ${activeTab === "payment" ? "active" : ""}`}
              onClick={() => setActiveTab("payment")}
            >
              <img
                className="navigation-icon"
                src={`${iconPath}${activeTab === "payment" ? "ticketVerification-active.svg" : "ticketVerification-inactive.svg"}`}
                alt="ticket-icon"
              />
            </button>
            <button
              className={`icon-button ${activeTab === "contact" ? "active" : ""}`}
              onClick={() => setActiveTab("contact")}
            >
              <img
                className="navigation-icon"
                src={iconPath + (activeTab === "contact" ? "contact-us.svg" : "contact-us-inactive.svg")}
                alt="contact-icon"
              />
            </button>
          </div>

          {/* Main Content */}
          <div className="main-content">
            <div className="greeting mb-4 flex justify-between items-center">
              <div>
                {adminDetails ? (
                  <h6 className="heading">Welcome, {adminDetails.first_name || adminDetails.username}</h6>
                ) : (
                  <h6 className="heading">Welcome, Guest</h6>
                )}
                <h6 className="current-date">{getFormattedDate()}</h6>
              </div>
              <div className="flex items-center">
                <span className="mr-2 font-medium">{eventName}</span>
                <span className="mr-2 text-sm text-gray-600">(ID: {eventID})</span>
                {eventsList.length > 0 && (
                  <button
                    onClick={handleChangeEvent}
                    className="px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700"
                  >
                    Change Event
                  </button>
                )}
              </div>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPage;