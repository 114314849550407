import React, { useState, useEffect, useCallback } from "react";

const Banner = ({ subheading, data, stats, onLinkClick }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = useCallback(() => {
    setSlide((prevSlide) => (prevSlide === data.length - 1 ? 0 : prevSlide + 1));
  }, [data.length]);

  const formatEventNameForUrl = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };

  const handleBannerClick = (e, name) => {
    // If there's an onLinkClick prop, call it first
    if (onLinkClick) {
      // If onLinkClick prevents default (returns false), don't proceed with navigation
      const shouldProceed = onLinkClick(e);
      if (e.defaultPrevented || shouldProceed === false) {
        return;
      }
    }
    
    const path = formatEventNameForUrl(name);
    window.location.href = `event-details/${path}`;
  };

  const handleError = useCallback(
    (index) => {
      const updatedData = [...data];
      updatedData[index].imageUrl = "/Adv_imgs/moqah_bg.png";
      data[index] = updatedData[index]; // Update image only for the affected slide
    },
    [data]
  );

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Automatically slide every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [nextSlide]);

  return (
    <div className="banner-container">
      <div className="heading-container">
        <div className="heading">
          <h6 style={{ color: "#7848F4", fontWeight: "bold" }}>My MoQah...</h6>
          <h6>
            the <span style={{ color: "#7848F4" }}>Best.</span>{" "}
            <span style={{ color: "#ff0000" }}>Day.</span> <span>Ever.</span>
          </h6>
        </div>
        <div className="subheading">
          <h6>{subheading}</h6>
        </div>
        <div className="stats">
          <div className="empty-container mr-3">
            <h6 className="empty-container-text">Explore</h6>
          </div>
          <div className="stats-container mr-3">
            <h6 className="stats-count">{stats[0]?.totalEventCount || 0}+</h6>
            <h6 className="stats-description">events hosted</h6>
          </div>
          <div className="stats-container mr-3">
            <h6 className="stats-count">{stats[1]?.activeEventCount || 0}+</h6>
            <h6 className="stats-description">events live</h6>
          </div>
          <div 
            className="stats-cta-container" 
            onClick={(e) => {
              if (onLinkClick) {
                onLinkClick(e);
              }
            }}
          >
            <h6 className="empty-container-text">Explore</h6>
          </div>
        </div>
      </div>
      <div className="carousel-container">
        <div className="carousel">
          {data.length === 1 ? (
            <div 
              className="slide" 
              onClick={(e) => handleBannerClick(e, data[0].name)}
            >
              <img
                src={data[0].imageUrl}
                alt={data[0].name}
                className="carousel-image"
                loading="lazy"
              />
            </div>
          ) : (
            data.map((item, idx) => (
              <div
                key={idx}
                className={slide === idx ? "slide" : "slide slide-hidden"}
                onClick={(e) => handleBannerClick(e, item.name)}
              >
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  onError={() => handleError(idx)}
                  className="carousel-image"
                  loading="lazy" // Lazily load images
                />
              </div>
            ))
          )}
          {data.length > 1 && (
            <div className="indicators">
              {data.map((_, idx) => (
                <button
                  key={idx}
                  className={slide === idx ? "indicator" : "indicator indicator-inactive"}
                  onClick={() => setSlide(idx)}
                ></button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
