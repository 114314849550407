import React, { useState, useEffect } from "react";
import PurchaseOrderSummary from "./PurchaseOrderSummary";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import PdfTicket from "./PdfTicket";
// import { pdf } from "@react-pdf/renderer";

const PaymentInstructions = ({
  eventData,
  selectedPaymentMethod,
  prevStep,
  ticketSelections,
  ticketOptions,
  invoiceNumber,
  generateInvoiceNumber,
  userDetails,
}) => {
  const navigate = useNavigate();
  const [transactionNumber, setTransactionNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null); // Use state for the file
  const [errorText, setErrorText] = useState("");
  const [errorTextReceipt, setErrorTextReceipt] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  let totalPrice = 0;


  const getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    else return token;
  };

  const ticketTypes = () => {
    let ticketTypes = "";
    ticketSelections.forEach(
      (selection) =>
      (ticketTypes += `${selection.qty} x ${ticketOptions.find((option) => option.id === selection.id).type
        }, `)
    );
    return ticketTypes.replace(/,\s*$/, "");
  };

  const ticketPrices = () => {
    let ticketPrices = "";
    ticketSelections.forEach((selection) => {
      const price = ticketOptions.find(
        (option) => option.id === selection.id
      ).price;
      totalPrice += price * selection.qty;
      ticketPrices += `${selection.qty} x Rs. ${price}, `;
    });
    return ticketPrices.replace(/,\s*$/, "");
  };

  const handleConfirmPayment = async () => {
    // If already submitting, prevent double submission
    if (isSubmitting) {
      return;
    }

    if (!transactionNumber.trim() && !file) {
      setErrorText("Please enter a transaction number");
      setErrorTextReceipt("Please upload a receipt");
      return;
    }

    if (!transactionNumber.trim()) {
      setErrorText("Please enter a transaction number");
      return;
    }

    if (!file) {
      setErrorTextReceipt("Please upload a receipt");
      return;
    }

    try {
      // Set submitting state to true
      setIsSubmitting(true);

      const reader = new FileReader();
      const fileExtension = file.name.split('.').pop().toLowerCase();

      const attachmentPromise = new Promise((resolve, reject) => {
        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          resolve({
            data: base64Data,
            extension: fileExtension
          });
        };
        reader.onerror = () => reject(new Error("Error reading file"));
      });

      reader.readAsDataURL(file);

      const attachmentInfo = await attachmentPromise;
      await sendPaymentData(attachmentInfo);
    } catch (error) {
      console.error("Error processing file:", error);
      setErrorText("Error processing receipt file. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setErrorText(""); // Clear any previous errors

    if (!uploadedFile) {
      return;
    }

    // Check file type
    if (!["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(uploadedFile.type)) {
      setErrorText("Please upload a PDF or image file");
      return;
    }

    // Check file size (5MB limit)
    if (uploadedFile.size > 5 * 1024 * 1024) {
      setErrorText("File size exceeds 5MB. Please upload a smaller file.");
      return;
    }

    setFile(uploadedFile);
  };

  const sendPaymentData = async (attachmentInfo) => {
    const paymentData = {
      tickets: ticketSelections.reduce((acc, selection) => {
        acc[selection.id] = selection.qty;
        return acc;
      }, {}),
      invoice_number: invoiceNumber || generateInvoiceNumber(),
      payment_method: selectedPaymentMethod,
      price: totalPrice,
      // payment_date: Date.now(),
      user_id: userDetails.id,
      event_id: eventData.id,
      transaction_number: transactionNumber,
      user_email: userDetails.email,
      contact: userDetails.contact,
      admin_email: eventData.admin_email,
      attachment: {
        name: `receipt.${attachmentInfo.extension}`,
        buffer: attachmentInfo.data,
      },
    };

    try {
      const token = getToken();
      if (!token) window.location.href("/login");

      // const pdfDoc = await pdf(<PdfTicket invoice={invoiceNumber} eventData={eventData} ticketSelections={ticketSelections} ticketOptions={ticketOptions} userDetails={userDetails} />).toBlob();
      // const formData = new FormData();
      // formData.append("pdf", pdfDoc, "ticket.pdf");
      const response = await axios.post(
        `${apiUrl}/ticket/payments`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        const purchasedTickets = response.data.tickets;
        navigate("/purchase-confirmation", {
          state: {
            invoiceNumber,
            eventData,
            totalPrice,
            purchasedTickets,
            ticketOptions,
            userDetails,
            attachment: attachmentInfo.data,
          },
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error during payment:", error);
      setErrorText(error.response?.data?.error || "Error processing payment");
      throw error;
    }
  };

  const handleCancel = () => {
    window.location.href = "/";
  };

  return (
    <div className="purchase-payment-instructions-and-order-summary">
      <div className="purchase-payment-instruction-container">
        <h2 className="heading">Your Invoice Number:</h2>
        <h2 className="invoice-number">
          {invoiceNumber === "" ? generateInvoiceNumber() : invoiceNumber}
        </h2>
        <div className="payment-instructions-container">
          <h2 className="payment-instructions-heading">
            How to pay with {selectedPaymentMethod}:
          </h2>
          <p className="payment-instructions-text">
            1. Open your {selectedPaymentMethod} application
          </p>
          <p className="payment-instructions-text">
            2. Select Money Transfer option
          </p>
          <p className="payment-instructions-text">
            3. Select "{eventData.bank_details.bankName}" as bank payment method.
          </p>
          <p className="payment-instructions-text">
            4. Enter following details :
          </p>
            <p className="payment-instructions-text" style={{ marginLeft: "2em" }}>
              Account number: {eventData.bank_details.accountNumber || ""} <br />
              Account name: {eventData.bank_details.accountName || ""}
            </p>
          <p className="payment-instructions-text">
            5. Confirm your details and pay with ease.
          </p>
          <p className="payment-instructions-text">
            6. Upload your payment receipt for confirmation.
          </p>
        </div>
        <div className="input-container">
          <h6 className="input-label">Enter your Transaction Number: </h6>
          <input
            type="text"
            name="transaction-number"
            value={transactionNumber}
            onChange={(e) => setTransactionNumber(e.target.value)}
          />
          {errorText && <p className="error-text">{errorText}</p>}
        </div>
        <div className="receipt-upload-container">
          <h2 className="heading">Upload your receipt</h2>
          <label
            htmlFor="receipt-upload-input"
            className="receipt-upload-label"
          >
            {file ? (
              <div className="receipt-upload-label-success">
                <img
                  src={
                    process.env.PUBLIC_URL + "/icons/upload_confirmation.svg"
                  }
                  alt="Tick Icon"
                />
                <span className="receipt-file-name">{file.name}</span>
              </div>
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/icons/upload.svg"}
                alt="Upload Icon"
              />
            )}
          </label>
          <input
            id="receipt-upload-input"
            className="receipt-upload-input"
            type="file"
            accept=".pdf, .jpg, .jpeg, .png"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {errorTextReceipt && <p className="error-text">{errorTextReceipt}</p>}
        </div>
        <div className="payment-instructions-actions-button-container">
          <button
            className="payment-instructions-confirm-button"
            disabled={isSubmitting}
            onClick={handleConfirmPayment}
          >
            {isSubmitting ? "Processing..." : "Confirm Payment"}
          </button>
          <button
            className="payment-instructions-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <PurchaseOrderSummary
        eventData={eventData}
        ticketTypes={ticketTypes()}
        ticketPrices={ticketPrices()}
        totalPrice={totalPrice}
      />
    </div>
  );
};

export default PaymentInstructions;
